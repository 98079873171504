import React from "react";
import {Link} from "gatsby"

export const getProductID = (productsGeneralData, expectedTitle, expectedHandle) => {
	return;
}

export const makeKeyFromTitle = (title) => {
	if (title == null) {
		console.log('Error: randomKey Generated');
		return 'randomKey' + Math.floor((Math.random() * 10) + 1);
	}
	let key = title.replace(/\W/g, '');
	key =
		key.substring(0, 3) +
		key.substring(key.length / 2 - 2, key.length / 2 + 2) +
		key.substring(key.length - 3, key.length);
	key = key.toLowerCase();
	return key;
}

export const formatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
});

export const getDataFromArrayGivenLocale = (dataArray, locale) => {
	return dataArray
		.filter((dataElement) => {
			return dataElement.languageCode === locale;
		})[0];//Just the only element is needed, not the array;
}

export const getDataFromNodes = (dataArray, locale, productHandle) => {
	return dataArray
		.filter((dataElement) => {
			return dataElement.locale === locale && dataElement.productHandle === productHandle;
		})[0];//Just the only element is needed, not the array;
}

export const getImageGivenHandle = (imagesData, handle) => {
	const image = imagesData.filter((imageData) => {
		return (imageData.name.replace("product-", "") === handle)
	});
	//The product does not have image => return placeholder image
	if (image.length === 0) {
		return getImageGivenHandle(imagesData, "placeholder")
	}
	return image[0].childImageSharp.fluid
}

export const FlexibleLink = (props) => {
	if (props.to.includes(props.to, "http")) {
		return (<a href={props.to}>{props.children}</a>);
	}
	return (<Link to={props.to}>{props.children}</Link>)
}