import React from 'react';
import WaistManual from "../../components/WaistManual";
import useWaistFRImages from "../../hooks/useWaistFRImages";

const locale = "fr";
export default () => {
	const images = useWaistFRImages();
	return (
		<>
			<WaistManual locale={locale} images={images}/>
		</>
	);
}
