import {useStaticQuery, graphql} from "gatsby"

export default () => {
	const waistImages = useStaticQuery(graphql`
    query{
      allFile(filter: {relativePath: {regex: "/(waistFR_)/"}}) {
        nodes {
          name
          relativePath
          childImageSharp {
            fluid(quality: 100) {
              base64
                ...GatsbyImageSharpFluid
            }
          }
        }
      }
    } 
  `).allFile.nodes
	return waistImages
}
