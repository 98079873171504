import React, {useState} from 'react';
import Img from "gatsby-image"
import styled from "styled-components";
import {makeKeyFromTitle} from "../util/Util";

const breakPoint = 768;
const Container = styled.div`
		display: flex; 
		justify-content: center;
		align-items: center;
		flex-direction: column;
	`
const ImgContainer = styled.div`
		width: 100vw;
		@media all and (min-width: ${breakPoint + 1}px) {
			width: 50vw;
		}   
	`

export default (props)=>{

	const getImage = (n, images)=>{
		let nStr = n.toString()
		if(nStr.length===1) nStr = "0"+nStr

		for (let i = 0; i < images.length; i++) {
			if(images[i].name.includes(nStr))
			  return images[i];
		}
	}

	const sortImages = (images) => {
		let imagesSorted = []
		for(let i=1; i<=images.length; i++){
			imagesSorted.push(getImage(i, images));
		}
		return imagesSorted;
	}

	const imagesSorted = sortImages(props.images);

	const Images = imagesSorted.map((imgData) => {
		const keyContainer = "cont_" + makeKeyFromTitle(imgData.name);
		return(
			<ImgContainer key={keyContainer}>
				<Img
					fluid={imgData.childImageSharp.fluid}
					key={makeKeyFromTitle(imgData.name)}
				/>
			</ImgContainer>
		)
	})
	return (
		<Container>
				{Images}
		</Container>
	)
}